import {getEventId, isPastOrCanceled} from '@wix/wix-events-commons-statics/dist/selectors/event'
import * as React from 'react'
import {EventsListProps} from '.'
import {hasOrder, isExpanded} from '../../selectors/event'
import {DetailedEvent} from '../../types/state'
import {CancelRsvpButton} from '../actions/cancel-rsvp-button'
import {DetailsButton} from '../actions/details-button'
import {DownloadTicketsButton} from '../actions/download-tickets-button'
import {GoToOrderButton} from '../actions/go-to-order-button'
import {ShareButton} from '../actions/share-button'
import {EventDetails} from '../event-details'
import {EventSummary} from '../event-summary'
import {ExpandableRow} from '../expandable-row'
import {NoEvents} from '../no-events'
import * as s from './events-list.scss'

export const EventsList = ({
  events,
  expandedEvents,
  toggleEventDetails,
  isOwner,
  loading,
  hasNoEvents,
  mobile,
  closeAllEvents,
  t,
}: EventsListProps) => {
  if (hasNoEvents && !loading) {
    return <NoEvents />
  }

  const handleToggle = (evId: string) => {
    if (evId === expandedEvents[0]) {
      closeAllEvents()
    } else {
      closeAllEvents()
      toggleEventDetails(evId)
    }
  }

  return (
    <div className={mobile ? s.mobile : null}>
      {events.map(event => {
        const eventId = getEventId(event.event)
        return (
          <ExpandableRow
            key={eventId}
            isExpanded={isExpanded(expandedEvents, event)}
            toggle={() => handleToggle(eventId)}
            arrowClass={s.arrow}
            mobile={mobile}
            t={t}
            details={
              <EventDetails
                event={event}
                primaryButton={<DetailsButton event={event} />}
                secondaryButton={<Action mobile={mobile} event={event} isOwner={isOwner} />}
                shareButton={<ShareButton event={event} />}
              />
            }
          >
            <EventSummary event={event} />
          </ExpandableRow>
        )
      })}
    </div>
  )
}

const Action = ({isOwner, mobile, event}: {isOwner: boolean; mobile: boolean; event: DetailedEvent}) => {
  if (!isOwner) {
    return null
  }
  if (hasOrder(event)) {
    if (mobile) {
      return <GoToOrderButton event={event} />
    } else {
      return <DownloadTicketsButton event={event} />
    }
  }
  if (isPastOrCanceled(event.event)) {
    return null
  }
  return <CancelRsvpButton event={event} />
}
